.dwelo-avatar {
  background-color: #4ad4d4 !important;
}
.gray-avatar {
  background-color: #838383 !important;
}
.override {
  float: right;
  margin-top: -40px;
  margin-right: 16px;
}
